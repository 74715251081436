
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, IonButtons, menuController } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import { dateFormat } from "../services/utils";
import { openToast } from "../services/toast";

import apiSpese from "../services/spese";

import ModalNuovaNotaSpesa from "../components/ModalNuovaNotaSpesa.vue";
//import RichiestaDetail from "../components/RichiestaDetail.vue";
import NotaSpesaDetail from "../components/NotaSpesaDetail.vue";

export default {
  name: "NotaSpese",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonIcon,
    IonButton,
    IonButtons,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

    const note_spese = ref([]);

    const openMenu = () => {
      menuController.open("app-menu");
    };

    function setBadgeClass(rimborsata) {
      if (rimborsata == 1) {
        return "badge_success";
      }
    }

    /**
     * Get all nota spese from the server
     */
    async function loadNotaSpese() {
      //console.log("getting richieste from server...");
      loading.value = true;
      try {
        note_spese.value = await apiSpese.getSpese(dipendenteID);
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta dei dati", "toast_danger");
      }
      loading.value = false;
    }

    /**
     * Open modal to create new presenza
     */
    async function openModal() {
      const modal = await modalController.create({
        component: ModalNuovaNotaSpesa,
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          note_spese.value = [detail.data, ...note_spese.value];
          openToast("Nota spesa inserita correttamente", "toast_success");
        }
      });
      return modal.present();
    }

    /**
     * ! Limit note spesa length to 40 charactes
     */
    function setShortDescription(description) {
      if (!description) {
        return;
      } else {
        const trimmedString = description.length > 45 ? description.substring(0, 42) + "..." : description;
        return trimmedString;
      }
    }

    /**
     * Passing id to retrive detail in NotaSpesaDetail page
     */
    async function openDetailModal(spesaDetail) {
      //console.log(spesaDetail);
      const modal = await modalController.create({
        component: NotaSpesaDetail,
        componentProps: {
          data: spesaDetail,
        },
      });
      return modal.present();
    }

    onMounted(() => {
      loadNotaSpese();
    });

    return {
      loading,
      dateFormat,
      add,
      refresh,
      openModal,
      //New field
      loadNotaSpese,
      openDetailModal,
      openMenu,
      menu,
      note_spese,
      setBadgeClass,
      setShortDescription,
    };
  },
};
